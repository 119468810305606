import LazyLoad from 'react-lazy-load';
import WithRtl from '../../../shared/WithRtl';
import { FC, lazy, useMemo, useState } from 'react';
import './styles/GlobalNetworkPromotion.scss';
import { useReusableContent } from '../../../hooks/useReusableContent';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import ScheduleLine, {
  ScheduleLineRenderProps
} from '../../../shared/Schedules/ScheduleLine/ScheduleLine';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { appHelper } from '../../../helpers/appHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useIsMobileNewUi } from '../../../hooks/useIsMobileNewUi';
import { useIsMobileShared } from '../../../hooks/useIsMobileShared';

export type GlobalNetworkLink = {
  Link: string;
  Target: string;
  Name: string;
};

export type GlobalNetworkPromotionProps = {
  globalNetworkLink?: GlobalNetworkLink;
  tradesAndServicesLink?: GlobalNetworkLink;
  linesByPortsLink?: GlobalNetworkLink;
  bgUrl?: string;
  bgRtlUrl?: string;
  bgMobileUrl?: string;
};

const RTL = lazy(() => import('./GlobalNetworkPromotionRtl'));
const GlobalNetworkPromotion: FC<GlobalNetworkPromotionProps> = ({
  globalNetworkLink,
  tradesAndServicesLink,
  linesByPortsLink,
  bgUrl,
  bgRtlUrl,
  bgMobileUrl
}) => {
  const [isGlobalLoaded, setIsGlobalLoaded] = useState<boolean>(false);
  const { getReusableContent } = useReusableContent();
  const [cookies, setCookie] = useCookies(['language', 'country', 'countryName']);
  const [showTradeLines, setShowTradeLines] = useState<boolean>(false);

  const { t } = useTranslation();
  const translations = useMemo(() => {
    return {
      goBack: t('widgets.GlobalNetworkPromotion.goBack'),
      lineRoute: t('widgets.GlobalNetworkPromotion.LineRoute')
    };
  }, [t]);

  const toggleTradeLines = () => {
    setShowTradeLines(!showTradeLines);
  };

  const renderOpt: ScheduleLineRenderProps = useMemo(
    () => ({
      holderClass: 'row line-search-form align-items-end g-0 g-md-3',
      typeaheadClass: 'col-sm-5 col-12 input-wrapper',
      showLabels: true,
      submit: {
        cssClass: 'btn btn-primary col-sm-2 col-12',
        value: translations.lineRoute
      },
      mode: 'global-network'
    }),
    []
  );
  const isMobile = useIsMobileShared(768);
  const bgImage = isMobile ? bgMobileUrl : appHelper.isRtl ? bgRtlUrl : bgUrl;

  return (
    <LazyLoad offset={100} onContentVisible={() => setIsGlobalLoaded(true)}>
      <>
        <WithRtl lazyElement={RTL} />
        <div className="gnp-container" style={{ backgroundImage: `url('${bgImage}')` }}>
          {showTradeLines && (
            <div className="row gnp-header">
              <div className="col-12 text-start">
                <FontAwesomeIcon
                  className="link-arrow me-2"
                  icon={appHelper.isRtl ? faArrowRight : faArrowLeft}
                />
                <a className="go-back-link" onClick={toggleTradeLines}>
                  {translations.goBack}
                </a>
              </div>
            </div>
          )}
          <div
            className={classNames('gnp-controls', {
              extra: showTradeLines,
              regular: !showTradeLines
            })}>
            {!showTradeLines && (
              <div className="row main-row">
                <div
                  className="col-12 text mb-3 mb-lg-2"
                  dangerouslySetInnerHTML={{
                    __html: getReusableContent('globalNetworkPromotion.Text')
                  }}></div>
                <div className="col-12 btn-row row mt-4 mt-lg-5">
                  {globalNetworkLink?.Link && (
                    <div className="col-12 col-lg-4 my-2 my-lg-0">
                      <a
                        className="btn btn-primary"
                        href={globalNetworkLink?.Link}
                        target={globalNetworkLink?.Target}>
                        {globalNetworkLink?.Name}
                      </a>
                    </div>
                  )}
                  {tradesAndServicesLink?.Link && (
                    <div className="col-12 col-lg-4 my-2 my-lg-0">
                      <a
                        className="btn btn-primary"
                        href={tradesAndServicesLink?.Link}
                        target={tradesAndServicesLink?.Target}>
                        {tradesAndServicesLink?.Name}
                      </a>
                    </div>
                  )}
                  {linesByPortsLink?.Link && (
                    <div className="col-12 col-lg-4 my-2 my-lg-0">
                      <a
                        className="btn btn-primary"
                        href={linesByPortsLink?.Link}
                        target={linesByPortsLink?.Target}>
                        {linesByPortsLink?.Name}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            )}
            {showTradeLines && (
              <div className="row main-row">
                <div
                  className="col-12 text mb-3 mb-lg-2"
                  dangerouslySetInnerHTML={{
                    __html: getReusableContent('globalNetworkPromotion.SelectShipping')
                  }}></div>
                <div className="col-12 btn-row row mt-2 mt-lg-5">
                  <ScheduleLine
                    mode="globalNetwork"
                    inputElement={true}
                    render={renderOpt}
                    quickActions={true}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div></div>
      </>
    </LazyLoad>
  );
};
export default GlobalNetworkPromotion;
